window.onload = function() {
    window.addEventListener('scroll', (event) => {
        checkTopScrollWindow()
    });

    checkTopScrollWindow();
    acoesMobile();

    window.addEventListener('resize', (event) => {
        acoesMobile()
    });

    if (jQuery('.navbar-toggler').length) {

        jQuery('.navbar-toggler').click(function() {
            jQuery('.navbar-collapse').addClass('show');
        });
    }

    if (jQuery('.btn-close-menu').length) {

        jQuery('.btn-close-menu').click(function() {
            jQuery('.navbar-collapse').removeClass('show');
        });
    }

    if (jQuery('.load-more').length) {
        jQuery('.load-more').click(async function(event) {
            event.preventDefault();
            jQuery('.loading').removeClass('hide')
            jQuery('.load-more').addClass('hide')
            const data = {
                'action': 'loadmore',
                'query': posts,
                'page': current_page
            };

            jQuery.ajax({
                url: scripts_main_params.ajaxurl,
                data: data,
                method: 'POST',
                dataType: 'html',
                success: function(result) {
                    console.log('result', result);
                    if (result) {
                        current_page++;
                        jQuery('.loading').addClass('hide')
                        jQuery('.load-more').removeClass('hide')
                        jQuery('.posts-grid').append(result)

                        if (current_page == max_page)
                            jQuery('.load-more').remove();

                    } else {
                        jQuery('.load-more').remove();
                    }
                }
            })
        });

    }
}

function acoesMobile() {
    // const isHeaderInterno = jQuery('.header').hasClass('header-intern');
    if (isMobile()) {
        jQuery('.header').addClass('header-fixed');
    } else {
        jQuery('.header').removeClass('header-fixed');
    }
}

function checkTopScrollWindow() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    // const isHeaderInterno = jQuery('.header').hasClass('header-intern');
    if (!isMobile()) {
        if (top > 100) {
            jQuery('.header').addClass('header-fixed');
        } else {
            jQuery('.header').removeClass('header-fixed');
        }
    }
};

function isMobile() {
    const doc = document.documentElement;
    const width = (window.screen.width || doc.screen.width);

    if (width <= 1100) {
        return true;
    }

    return false;
};